import React from 'react'

class HeaderContainer extends React.Component {
    renderChildernDirection(direction, children){
        switch(direction){
            case 'center':
                return(
                    <div style={centerAlign}>
                        {children}
                    </div>
                );
                
            case 'left':
                return(
                    <div style={leftAlign}>
                        {children}
                    </div>
                );

            case 'right':
                return(
                    <div style={rightAlign}>
                        {children}
                    </div>
                );

            default:
                return (
                    <div>
                        Choose an alignment
                    </div>
                )
        }
    }

    render(){
        return(
            <div style={styling}>
                {this.renderChildernDirection(this.props.direction, this.props.children)}
            </div>
        )
    }
}

const styling = {
    flexGrow: 1,
}

const centerAlign = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    height: '50px',
    alignSelf: 'center',
}

const leftAlign = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    height: '50px',
    alignSelf: 'flex-start',
}

const rightAlign = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    height: '50px',
    alignSelf: 'flex-end',
}

export default HeaderContainer