import React from 'react'
import './ComponentStyling/Header.css'

class Header extends React.Component {
    lockHeader(){
        if(this.props.lock){
            return(
                <div className={"lockedStyle"}>
                    {this.props.children}
                </div>
            )
        }
        else{
            return(
                <div className={'styling'}>
                    {this.props.children}
                </div>
            )
        }
    }
    render(){
        return(
            <div>
                {this.lockHeader()}
            </div>
        )
    }
}

export default Header