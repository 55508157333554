import React from 'react'

class ContentContainer extends React.Component {
    render(){
        return(
            <div style={{
                display: 'flex',
                flexDirection: this.props.contentDirection,
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '15px',
                marginBottom: '45px',
                backgroundColor: this.props.color,
            }}>
                {this.props.children}
            </div>
        )
    }
}

export default ContentContainer