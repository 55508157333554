import React from 'react'
import './ComponentStyling/Header.css'
import './ComponentStyling/ButtonStyling.css'

class TabController extends React.Component {
    state = {
        index: 0
    }

    rednerTabs(){
        return this.props.children.map((item, i)=>{
            return <button className={'tab-buttons'} key={i} onClick={(e) => this.onItemClick(i)}>{item.props.name}</button>
        })
    }

    onItemClick (ind) {
        this.setState((state)=>({
            index: ind
        }))
    }

    renderTabItem(){
        console.log(this.state.index)
        let output = ''
        switch(this.state.index){
            case 0:
                output = this.props.children[0]
                break
            case 1:
                output =  this.props.children[1]
                break
            case 2:
                output =  this.props.children[2]
                break
            case 3:
                output =  this.props.children[3]
                break
            case 4:
                output =  this.props.children[4]
                break

            default:
                output = <div> Broken </div>
                break
        }
        return output
    }

    render(){
        return(
            <div>
                <div className="tab-name-container">
                    {this.rednerTabs()}
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',}}>
                    {this.renderTabItem()}
                </div>
            </div>
        )
    }
}



export default TabController;