import React from 'react'

class Spacer extends React.Component {
    render(){
        return(
            <div style={{margin: this.props.space}}></div>
        )
    }
}

export default Spacer