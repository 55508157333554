import React from 'react';
import Header from './Components/Header'
import HeaderContainer from './Components/HeaderContainer'
import Spacer from './Components/Spacer'
import ContentContainer from './Components/ContentContainer'
import BoxContainer from './Components/BoxContainer'
import PaperContainer from './Components/PaperContainer'
import TabController from './Components/TabController'
import photo from './Resources/Images/cjahwaan.JPG'
import './App.css';

function App() {
  return (
    <div className="App">

      <Header lock={true}>
        <HeaderContainer direction={'center'}>
          <h1>
            Jahwaan Crews
          </h1>
        </HeaderContainer>
        <HeaderContainer direction={'center'}>
          <a style={{
            color: '#aaa',
            textDecoration: 'none',
          }} href={'mailto:cjahwaan@gmail.com'}>cjahwaan@gmail.com</a>
        </HeaderContainer>
      </Header>

      <ContentContainer>
        <div style={{
              width: '400px',
              height: '400px',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: '400px',
              borderWidth: '5px',
              borderStyle: 'solid',
              borderColor: '#efefef',
              backgroundPosition: '0px 0px',
              transform: `rotate(0deg)`,
              backgroundImage: `url(${photo})`,
            }}></div>
      </ContentContainer>

      <Spacer space={'100px'}/>

      <ContentContainer>
        <BoxContainer color={'white'} width={'200px'}>
          <h1>
            Education
          </h1>
        </BoxContainer>
        <BoxContainer color={'#eee'} width={'500px'} flexDirection={'column'}>
          <h4>
            Sam Houston State University
          </h4>
          <p>
            ABET
          </p>
          <p>
            Bachelor of Science: Computer Science
          </p>
          <p>
            May 2020
          </p>
        </BoxContainer>
      </ContentContainer>

      <ContentContainer>
        <BoxContainer color={'white'} width={'200px'}>
          <h1>
            Projects
          </h1>
        </BoxContainer>
        <BoxContainer color={'#eee'} width={'500px'}>
          <h4>
          Cancer Career Research Application/Website 
          </h4>
          <p>
          Engineered a website and iOS application for Cancer Career Research for Sam Houston State University.
          </p>
        </BoxContainer>
      </ContentContainer>

      <ContentContainer>
        <BoxContainer color={'white'} width={'200px'}>
          <h1>
            Activites
          </h1>
        </BoxContainer>
        <BoxContainer color={'#eee'} width={'500px'}>
        <BoxContainer color={'white'}>
            <h4>
            Vice President for ACM Houston State University Chapter
            </h4>
            <p>
            January 2019 - May 2019
            </p>
          </BoxContainer>
          <BoxContainer color={'white'}>
            <h4>
            Secretary for ACM Sam Houston State University Chapter
            </h4>
            <p>
            September 2018 - January 2019
            </p>
          </BoxContainer>
          
        </BoxContainer>
      </ContentContainer>

      <ContentContainer>
        <BoxContainer color={'white'} width={'200px'}>
          <h1>
            Skill
          </h1>
        </BoxContainer>
        <BoxContainer color={'#eee'} width={'500px'} height={'500px'}>
          <BoxContainer color={'white'} flexDirection={'column'}>
          <h4>
            c++
          </h4>
          <p>
            Moderate
          </p>
          </BoxContainer>
          <BoxContainer color={'white'} flexDirection={'column'}>
          <h4>
            c#
          </h4>
          <p>
            Moderate
          </p>
          </BoxContainer>
          <BoxContainer color={'white'} flexDirection={'column'}>
          <h4>
            Python
          </h4>
          <p>
            Moderate
          </p>
          </BoxContainer>
          <BoxContainer color={'white'} flexDirection={'column'}>
          <h4>
            Django
          </h4>
          <p>
            Beginner
          </p>
          </BoxContainer>
          <BoxContainer color={'white'} flexDirection={'column'}>
          <h4>
            Java
          </h4>
          <p>
            Beginner
          </p>
          </BoxContainer>
          <BoxContainer color={'white'} flexDirection={'column'}>
          <h4>
            Web Developer
          </h4>
          <p>
            Beginner
          </p>
          </BoxContainer>
          <BoxContainer color={'white'} flexDirection={'column'}>
          <h4>
            React
          </h4>
          <p>
            Beginner
          </p>
          </BoxContainer>
        </BoxContainer>
      </ContentContainer>
      <Spacer space={'100px'}/>
    </div>
  );
}

export default App;