import React from 'react'
import './ComponentStyling/Header.css'

class PaperContainer extends React.Component {
    state = {
        name: this.props.name
    }
    render(){
        return(
            <div style={{width: '70rem', textAlign: 'left'}}>
                {this.props.children}
            </div>
        )
    }
}

export default PaperContainer;