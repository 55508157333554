
import React from 'react'

class BoxContainer extends React.Component {

    returnBackground(should){
        console.log('Should fill: ' + should)
        if(should){
            return this.props.color
        }
        else{
            return null
        }
    }

    render(){
        return(
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: this.props.flexDirection,
                flexWrap: 'wrap',
                padding: '15px',
                width: this.props.width,
                height: this.props.height,
                borderStyle: 'solid',
                borderColor: this.props.color,
                backgroundColor: this.returnBackground(this.props.fill),
                }}>
                {this.props.children}
            </div>
        )
    }
}

export default BoxContainer